<template>
  <hr
    :class="[
      `separator${notDefault ? ' separator-' + level : ''}`,
      { 'non-default': notDefault },
      { 'x-margin-pop': xMarginPop },
      { 'y-margin-pop': yMarginPop },
      { 'full-margin-pop': fullMarginPop },
    ]"
  />
</template>

<script setup lang="ts">
// interface LevelSwitch {
//   level: number;
//   control: boolean;
// }

// style in _global.scss
const props = defineProps({
  level: {
    type: Number,
    default: -1,
  },
  default: {
    type: Boolean,
    default: true,
  },
  xMarginPop: {
    type: Boolean,
    default: false,
  },
  yMarginPop: {
    type: Boolean,
    default: false,
  },
  fullMarginPop: {
    type: Boolean,
    default: false,
  },
});

// const calcSeparatorLevel = computed<LevelSwitch>(() => ({ level: 0, control: true }));
const notDefault = computed<boolean>(() => props.level !== -1 || !props.default);
// const inRange = computed<boolean>(() => props.level !== -1 && props.level < 6);
</script>
